.homePage {
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  background-position: center;
  background-size: cover;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
