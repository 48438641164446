.movieResult {
  width: 100%;
  padding: 10px;
}

.resultsContainer {
  display: flex;
  flex-direction: row;
}

.movieContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.button {
  margin: 10px;
  font-family: var(--font-default);
  font-size: 1.2rem;
  font-weight: 500;
  background-color: white;
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  color: var(--color-text);
  padding: 5px 20px;
  cursor: pointer;
}

@media screen and (max-width: 519px) {
  .movieContainer {
    justify-content: center;
  }
}
