.card {
  width: 235px;
  margin: 5px 5px;
  text-align: center;
  border: 1px solid var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  top: 0;
  left: 0;
}

.image {
  min-height: 360px;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.poster {
  width: 100%;
  height: 100%;
}

.button {
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-background);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 10px;
  opacity: 0.6;
}

.button:hover {
  background-color: var(--color-background);
}

.iconButton {
  font-size: 0.8rem;
  font-weight: 600;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 110px;
}

.title {
  font-family: var(--font-default);
  font-size: 15px;
  font-weight: 600;
  color: var(--color-text);
  width: 100%;
  min-height: 40px;
  text-align: left;
  vertical-align: top;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
}

.date {
  font-family: var(--font-default);
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  color: var(--color-text);
  padding: 5px 0;
  max-width: 40%;
  min-height: 22px;
  vertical-align: top;
  white-space: nowrap;
  margin-bottom: 5px;
}

.genres {
  font-family: var(--font-default);
  font-size: 14px;
  font-weight: 300;
  color: var(--color-text);
  white-space: pre-wrap;
  text-align: left;
  vertical-align: top;
  padding-bottom: 5px;
}

.modalTitle {
  font-family: var(--font-default);
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-text);
  width: 100%;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-bottom: 10px;
  font-weight: bold;
}

.modalText {
  font-family: var(--font-default);
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text);
  padding: 5px 30px;
  margin-bottom: 10px;
  font-weight: bold;
}
