.header {
  width: 100%;
  background: url("../../images/2.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  flex-shrink: 0;
}

.header:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.siteName {
  font-family: var(--font-default);
  font-weight: 600;
  color: var(--color-primary);
  font-size: 3rem;
  padding: 1.5rem 0 1.2rem 0.5rem;
  text-transform: uppercase;
  text-shadow: 0px 14px 10px rgba(0, 0, 0, 0.15),
    0px 24px 2px rgba(0, 0, 0, 0.1), 0px 34px 30px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1030px) {
  .siteName {
    font-weight: 500;
    font-size: 2.5rem;
    padding: 1.2rem 0 1.4rem 0.5rem;
  }
}

@media screen and (max-width: 425px) {
  .siteName {
    font-weight: 500;
    font-size: 2rem;
    padding: 1rem 0 1.4rem 0.5rem;
  }
}

@media screen and (max-width: 375px) {
  .siteName {
    font-weight: 400;
    font-size: 1.5rem;
    padding: 1rem 0 1.4rem 0.5rem;
  }
}
