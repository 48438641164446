.searchComponent {
  width: 100%;
  height: 100%;
  background: url("../../images/3.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.searchComponent:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.searchContainer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px;
}

.text {
  font-family: var(--font-default);
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-primary);
  text-align: left;
  padding: 0.6rem 0 0.6rem 0;
  text-transform: uppercase;
  text-align: center;
}

.searchby {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 0 0 1rem 0;
}

label {
  margin-right: 2rem;
}

label:last-child {
  margin-right: 0rem;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--color-primary);
}
input[type="radio"]:checked {
  color: var(--color-primary);
}
input[type="radio"]:checked + *::before {
  background: radial-gradient(
    var(--color-primary) 0%,
    var(--color-primary) 40%,
    transparent 50%,
    transparent
  );
  border-color: var(--color-primary);
}

.titleRadioButton {
  padding: 1rem 0rem;
  font-family: var(--font-default);
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--color-primary);
  text-transform: uppercase;
}

.searchPanel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 1rem 0;
}

.searchInput {
  width: 50%;
  padding: 5px;
  font-family: var(--font-default);
  background-color: var(--color-background);
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--color-text);
  outline: none;
  border: none;
}

.searchButton {
  padding: 5px;
  font-family: var(--font-default);
  font-size: 1.1rem;
  font-weight: 400;
  background-color: var(--color-background);
  color: var(--color-text);
  cursor: pointer;
  border: none;
  border-left: 1px solid var(--color-primary);
}

@media screen and (max-width: 768px) {
  .searchInput {
    width: 80%;
  }
}

@media screen and (max-width: 523px) {
  .searchButton {
    padding: 5px 10px;
  }
  .searchText {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .searchButton {
    padding: 5px 15px;
  }
}
