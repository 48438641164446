.footer {
  width: 100%;
  background: url("../../images/footer1.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  flex-shrink: 0;
}

.footer:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.footerContainer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 30px 5px 30px;
}

.siteName {
  font-family: var(--font-default);
  font-weight: 500;
  color: var(--color-primary);
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.link {
  cursor: pointer;
}

.copyRight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;
}

.icon {
  font-family: var(--font-default);
  font-weight: 500;
  color: var(--color-primary);
  font-size: 0.8rem;
  margin-right: 5px;
}

.icon:last-child {
  margin-right: 0;
}

@media screen and (max-width: 770px) {
  .siteName {
    font-size: 1rem;
  }

  .footerItem,
  .link {
    font-size: 0.9rem;
  }
  .copyRight {
    margin-bottom: 0.5rem;
  }
}
