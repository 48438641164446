.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  animation-name: appear;
  animation-duration: 300ms;
}

.dialog {
  width: 100%;
  max-width: 750px;
  background: rgba(255, 255, 255, 1);
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
  border-radius: 5px;
}

.header,
.footer {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.header {
  justify-content: space-between;
}
.footer {
  justify-content: flex-end;
}
.modalclose {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  font-size: 2rem;
}
.body {
  overflow: auto;
}
.content {
  padding: 0 1rem 0.5rem 1rem;
}

.title {
  margin: 0;
  font-family: var(--font-default);
  color: var(--color-accent);
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}
