.noMatch {
  width: 100%;
  height: 100vh;
  background-color: var(--color-secondary);
  background-position: center;
  background-size: cover;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: var(--font-default);
  font-size: 5rem;
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text {
  font-family: var(--font-default);
  font-size: 2rem;
  font-weight: 400;
  color: var(--color-primary);
  margin-bottom: 5rem;
}

.button,
.link {
  font-family: var(--font-default);
  font-size: 1.2rem;
  font-weight: 500;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  color: var(--color-text);
  padding: 10px;
  cursor: pointer;
}

@media screen and (max-width: 740px) {
  .title {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  .text {
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }

  .button,
  .link {
    font-size: 1.2rem;
    font-weight: 500;

    padding: 10px;
  }
}

@media screen and (max-width: 490px) {
  .text {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 425px) {
  .text {
    padding: 0 30px;
  }
}
