* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}

:root {
  --font-default: "Lora";
  --font-secondary: "Lato";
  --color-background: #fcecdb;
  --color-text: #3a0402;
  --color-accent: #ca0915;
  --color-secondary: rgba(156, 44, 43, 1);
  --color-primary: #e5ba8d;
}

body {
  font-family: var(--font-default);
  background-color: var(--color-background);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-default);
}
