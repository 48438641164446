.container {
  width: 100%;
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.titleMovieDetails {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 15px 5px 5px 5px;
}

.wrapper {
  width: 25%;
}

.poster {
  max-width: 95%;
  max-height: 100%;
}

.details {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-left: 35px;
}

.title {
  font-family: var(--font-default);
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text);
  text-align: left;
  vertical-align: top;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
}

.text {
  font-family: var(--font-default);
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  color: var(--color-text);
  padding: 5px 0;
  vertical-align: top;
  margin-bottom: 5px;
}

.overview {
  font-family: var(--font-default);
  font-size: 16px;
  font-weight: 300;
  color: var(--color-text);
  padding: 20px;
  text-align: start;
}

.item {
  font-family: var(--font-default);
  font-size: 14px;
  font-weight: 300;
  color: var(--color-text);
}
