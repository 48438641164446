.App {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  height: 100vh;
}

.mainContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content {
  flex-shrink: 0;
  flex-grow: 1;
}
