.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-background);
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-primary);
}

.searchedMoviesAmount {
  font-family: var(--font-default);
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-text);
  margin: auto 0;
}

.sortPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sortby {
  margin: 10px;
  font-family: var(--font-default);
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-text);
  padding: 5px;
}

label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0rem;
  margin-right: 1.5rem;
}

.titleRadioButton {
  font-family: var(--font-default);
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--color-primary);
  text-transform: uppercase;
  vertical-align: middle;
}

@media screen and (max-width: 1000px) {
  .searchedMoviesAmount {
    font-size: 1rem;
  }
  .titleRadioButton {
    font-size: 1rem;
  }
}

@media screen and (max-width: 820px) {
  label {
    padding: 0.5rem 0rem;
  }
}

@media screen and (max-width: 640px) {
  .sortby {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .topBar {
    flex-direction: column-reverse;
    padding: 0 1rem;
  }

  .sortPanel {
    justify-content: center;
  }

  .searchedMoviesAmount {
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
}
